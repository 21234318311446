import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/qB01H8bmiYg"
    bibleGroupSrc="https://pdfhost.io/v/MJqkZIJpo_Microsoft_Word_Bible_Group_Homework_126docx.pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/47871409"
  >
    <SEO title="Worry World - Holiday on its Head" />
  </Layout>
)

export default SermonPost
